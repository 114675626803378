export default () => ({
  lineups: [],
  lineup_for_edit: {},
  lineup_comments: null,
  lineups_by_state: [],
  carouselKey: 0,
  players: [
    {
      photo: null,
      first_name: null,
      last_name: null,
      patronymic: null,
      birthdate: null,
      height: null,
      gender: null,
      level: null
    }
  ],
  new_team: {
    newCommand:true,
    logo: null,
    tournament: null,
    contact_person_name: null,
    contact_person_phone: null,
    rating:0,
    name: null,
    description: null,
    website_url: null,
    vk_group: null
  },
  showRequestDataExistModal: false,
  isRequestDataExist: 'yes',
  isSubmitted: false,
  open_tournaments: [],
  teams: [],
})
