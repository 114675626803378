export default {
  tournament_table_navigation: state => state.tournament_table_navigation,
  current_year: state => state.current_year,
  current_number: state => state.current_number,
  tournament_names: state => state.tournament_names,
  tournament_numbers: state => state.tournament_numbers,
  tournament_divisions: state => state.tournament_divisions,
  filters: state => state.filters,
  viewMode: state => state.viewMode,
  group_info: state => state.group_info,
  isMyTeam: state => state.isMyTeam,
  editTourMode: state => state.editTourMode,
  tour_for_edit: state => state.tour_for_edit,
  original_tour_for_edit: state => state.original_tour_for_edit,
  undefined_groups: state => state.undefined_groups,
  showUpdateResults: state => state.showUpdateResults,
  selectedTeamNumber: state => state.selectedTeamNumber,
  selectedTeam: state => state.selectedTeam
}
