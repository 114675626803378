export default () => ({
  tournament_table_navigation: null,
  current_year: '',
  tournament_names: [],
  tournament_numbers: [],
  tournament_divisions: [],
  group_info: null,
  viewMode: false,
  isMyTeam: false,
  editTourMode: false,
  original_tour_for_edit: [],
  tour_for_edit: [],
  undefined_groups: [],
  filters: {
    tour_name:'',
    tour_number:'',
    tour_year:''
  },
  showUpdateResults: false,
  selectedTeamNumber: 0,
  selectedTeam: {}
})
