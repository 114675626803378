import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// for development

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { noAuth: true },
    component: () => import('../pages/home/Home.vue'),
  },
  {
    path: '/tour',
    name: 'Tour',
    meta: { noAuth: true },
    component: () => import('../pages/tour/Tour.vue'),
  },
  {
    path: '/rating',
    name: 'Referee',
    meta: { noAuth: true },
    component: () => import('../pages/rating/Referee.vue'),
  },
	{
    path: '/players',
    name: 'Players',
    meta: { noAuth: true },
    component: () => import('../pages/rating/Players.vue'),
  },
  {
    path: '/teams',
    name: 'Teams',
    meta: { noAuth: true },
    component: () => import('../pages/rating/Teams.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: { noAuth: true },
    component: () => import('../pages/contacts/Contacts.vue'),
  },
  {
    path: '/new-application',
    name: 'NewApplication',
    component: () => import('../pages/new_applic/NewApplic.vue'),
  },
  {
    path: '/new-request',
    name: 'NewRequest',
    component: () => import('../pages/requests/new-request.vue'),
  },
  {
    path: '/my-requests',
    name: 'MyRequests',
    component: () => import('../pages/requests/my-requests.vue'),
  },
  {
    path: '/view-application',
    name: 'ViewApplication',
    component: () => import('../pages/view-applic/ViewApplic.vue'),
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: { noAuth: true },
    component: () => import('../pages/documents/Documents.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/login/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: { noAuth: true },
    component: () => import('../pages/register/Register.vue'),
  },
  {
    path: '/confirm',
    name: 'Confirm',
    meta: { noAuth: true },
    component: () => import('../pages/confirm/Confirm.vue'),
  },
  {
    path: '/confirm-password',
    name: 'ConfirmPassword',
    meta: { noAuth: true },
    component: () => import('../pages/confirm/ConfirmPassword.vue'),
  },
  {
    path: '/new-results/:id',
    name: 'NewResults',
    meta: { noAuth: true },
    props: true,
    component: () => import('../pages/new_results/NewResults.vue'),
  },
  {
    path: '/recovery',
    name: 'Recovery',
    meta: { noAuth: true },
    component: () => import('../pages/recovery/Recovery.vue'),
  },
  {
    path: '/password-sented',
    name: 'PasswordSented',
    meta: { noAuth: true },
    component: () => import('../pages/confirm/PasswordSented.vue'),
  },
  {
    path: '/new-password/:token',
    name: 'NewPassword',
    meta: { noAuth: true },
    component: () => import('../pages/new_password/NewPassword.vue'),
  },
  {
    path: '/tour-single/:id',
    name: 'TourSingle',
    component: () => import('../pages/tour/TourSingle.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
