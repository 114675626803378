console.log(`Version: ${process.env.PACKAGE_VERSION}`)

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import './plugins/auth'
import './plugins/cookie'
import './plugins/mask'
import './services'
import './layouts'
import './middleware'
import 'material-design-icons-iconfont'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
