import Vue from 'vue'
import serialize from "../../utils/serialize"
export default {
  async GET_OPEN_TOURNAMENTS({commit}){
    const {data: tournaments = []} = await Vue.axios.get(`/open_tournaments/`);
    commit('SET_OPEN_TOURNAMENTS', tournaments)
    return tournaments
  },
  async GET_TEAMS({commit}){
    const {data: teams = []} = await Vue.axios.get(`/teams/`);
    commit('SET_TEAMS', teams)
    return teams
  },
  async ADD_PLAYER(state, player){
    let formData = new FormData()

    if(player?.photo?.imageFile){
      formData.set('photo', player?.photo?.imageFile, player?.photo.imageFile.name)
    }
    formData.set('first_name', player?.first_name)
    formData.set('last_name', player?.last_name)
    formData.set('patronymic', player?.patronymic)
    formData.set('birthdate', player?.birthdate)
    formData.set('height', player?.height)
    formData.set('gender', player?.gender)

    const {data} = await Vue.axios.post('/players/', formData)
    return data?.id
  },
  async PATCH_PLAYER(state, player){
    let formData = new FormData()

    player ||= {}

    if(player.photo?.imageFile){
      formData.set('photo', player.photo?.imageFile, player?.photo.imageFile.name)
    } else {
      formData.delete('photo')
    }
    formData.set('first_name', player.first_name)
    formData.set('last_name', player.last_name)
    formData.set('patronymic', player.patronymic)
    formData.set('birthdate', player.birthdate)
    formData.set('height', player.height)
    formData.set('gender', player.gender)

    const {data} = await Vue.axios.patch(`/players/${player.id}/`, formData)
    return data?.id
  },
  async CREATE_LINEUPS(state, request){
    const {lineup, id} = request
    let formData = new FormData()
    if(lineup?.logo?.imageFile){
      formData.set('logo', lineup?.logo?.imageFile, lineup?.logo?.imageFile?.name)
    }
    formData.set('tournament', lineup?.tournament)
    formData.set('contact_person_name', lineup?.contact_person_name)
    formData.set('contact_person_phone', lineup?.contact_person_phone)
    formData.set('rating', lineup?.rating)
    formData.set('rating', lineup?.rating)
    formData.set('name', lineup?.name)
    formData.set('description', lineup?.description)
    formData.set('website_url', lineup?.website_url)
    formData.set('vk_group', lineup?.vk_group)
    for(const id of lineup?.playerIds) {
      formData.append('players', id)
    }

    if(id){
      return await Vue.axios.put(`/lineups/${id}/`, formData)
    }else{
      return await Vue.axios.post('/lineups/', formData)
    }
  },
  async GET_LINEUPS({commit}, type){
    const {data: lineups = []} = await Vue.axios.get(`/lineups/?tournament_type=${type}`);
    commit('SET_LINEUPS', lineups)
    return lineups
  },
  async GET_LINEUP_BY_ID({commit}, {id}){
    //const {data: lineup = []} = await Vue.axios.get(`/lineups/${id}/`);

    const {data: lineup = {}} = await Vue.axios.get(`/lineups/${id}/`);

    if (Object.keys(lineup.updated_data).length > 0) {
      lineup.players = lineup.players.filter(
          player => lineup.updated_data.players.includes(player.id)
      )
      for (let player of lineup.updated_data.new_players) {
        lineup.players.push({
          new: true,
          ...player
        })
      }
    }


    commit('SET_LINEUP_FOR_EDIT', lineup)
    return lineup
  },
  async GET_LINEUP_PLAYERS_BY_ID(state, id){
    const {data: lineup = []} = await Vue.axios.get(`/lineups/${id}/`);
    return lineup
  },
  async GET_LINEUP_COMMENTS({commit}, id) {
    const {data: lineup_comments} = await Vue.axios.get(`/lineups/comments/?lineup=${id}`)
    commit('SET_LINEUP_COMMENTS', lineup_comments.reverse())
    return lineup_comments
  },
  async ADD_LINEUP_COMMENT(state, comment) {
    const {data} = await Vue.axios.post(`/lineups/comments/?lineup=${comment.lineup}`, comment)
    return data
  },
  async GET_LINEUPS_BY_STATE ({commit}, _state){
    const {data: lineups = []} = await Vue.axios.get(`/lineups/?state=${_state}`);
    commit('SET_LINEUPS_BY_STATE', lineups)
    return lineups
  },
  async WITHDRAWN_LINEUP(state, id){
    return await Vue.axios.put(`/lineups/${id}/withdrawn/`);
  },
  async DELETE_LINEUP(state, id){
    return await Vue.axios.delete(`/lineups/${id}/`);
  },
  async REJECT_LINEUP(state, {id, isUpdate}){
    return await Vue.axios.put(`/lineups/${id}/${isUpdate ? 'reject_update/':'reject/'}`);
  },
  async ACCEPT_LINEUP(state, {id, isUpdate}){
    return await Vue.axios.put(`/lineups/${id}/${isUpdate ? 'accept_update/':'accept/'}`);
  },
  async GET_PLAYER(state, id){
    const {data} = await Vue.axios.get(`/players/${id}/`);
    return data
  },
  //async CHECK_PLAYER(state, player){
  async CHECK_PLAYER(state, player){

    let _player = {
      first_name : player?.first_name,
      last_name: player?.last_name,
      patronymic: player?.patronymic,
      birthdate: player?.birthdate
    }
    const {data: players} = await Vue.axios.get(`/players/?${serialize(_player)}`);
    const found = players.find(player => !player.is_duplicate)
    //const found = players.find(player => !player.is_duplicate && (forlineupPlayers.indexOf(player.id) != -1))
    return found || null
  }
}
