export default {
  SET_OPEN_TOURNAMENTS(state, payload) {
    state.open_tournaments = payload
  },
  SET_TEAMS(state, payload) {
    state.teams = payload
  },
  APPEND_PLAYER(state,player){
    let players = Object.assign(state.players)
    players.push(player)
    state.players = players
  },
  REMOVE_PLAYER(state, index){
    let players = Object.assign(state.players)
    players.splice(index - 1, 1);
  },
  REQUEST_TEAM_FORM_SUBMITTED(state){
    state.isSubmitted = true
  },
  SET_NEW_TEAM(state, payload) {
    state.new_team = payload
  },
  SET_NEW_PLAYERS(state, payload) {
    state.players = payload
  },
  SET_REQUEST_DATA_EXIST_MODAL(state, payload){
    state.showRequestDataExistModal = payload
  },
  SET_REQUEST_DATA_EXIST(state, payload){
    state.isRequestDataExist = payload
  },
  UPDATE_CAROUSEL(state) {
    state.carouselKey += 1
  },
  SET_LINEUPS(state, payload){
    state.lineups = payload
  },
  SET_LINEUP_FOR_EDIT(state, payload){
    state.lineup_for_edit = payload
  },
  SET_LINEUP_COMMENTS(state, payload){
    state.lineup_comments = payload
  },
  SET_LINEUPS_BY_STATE(state,payload){
    state.lineups_by_state = payload
  }
}
