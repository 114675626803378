export default {
  SET_TOURNAMENT_TABLE_NAVIGATION(state, payload) {
    state.tournament_table_navigation = payload
  },
  SET_CURRENT_TOURNAMENT_YEAR(state,payload){
    state.filters.tour_year = payload
  },
  SET_TOURNAMENT_NAMES(state,payload){
    state.tournament_names = payload
  },
  SET_TOURNAMENT_NUMBERS(state,payload){
    state.tournament_numbers = payload
  },
  SET_CURRENT_TOURNAMENT_NAME(state, payload){
    state.filters.tour_name = payload
  },
  SET_CURRENT_TOURNAMENT_NUMBER(state, payload){
    state.filters.tour_number = payload
  },
  SET_FILTERS(state,payload) {
    state.filters = payload
  },
  SET_TOURNAMENT_DIVISIONS(state, payload){
    state.tournament_divisions = payload
  },
  SET_GROUP_INFO(state,payload){
    state.group_info = payload
  },
  SET_VIEW_MODE(state,payload){
    state.viewMode = payload
  },
  SET_IS_MY_TEAM(state,payload){
    state.isMyTeam = payload
  },
  SET_EDIT_TOUR_MODE(state,payload){
    state.editTourMode = payload
  },
  SET_TOUR_UNDEFINED_GROUPS(state, payload){
    state.undefined_groups = payload
  },
  APPEND_TOUR_UNDEFINED_GROUPS(state, payload){
    state.undefined_groups = [...state.undefined_groups, ...payload]
  },
  SET_TOUR_FOR_EDIT(state,payload) {
    state.tour_for_edit = payload
  },
  SET_ORIGINAL_TOUR_FOR_EDIT(state,payload){
    state.original_tour_for_edit = payload
  },
  SHOW_UPDATE_RESULTS(state, payload){
    state.showUpdateResults = payload
  },
  SET_SELECTED_TEAM(state,payload){
    state.selectedTeam = payload
  },
  SET_SELECTED_TEAM_NUMBER(state,payload){
    state.selectedTeamNumber = payload
  }
}
