<template>
  <v-app>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "App",

  components: {},
  methods: {
    ...mapMutations({
      SET_USER_PROFILE: "SET_USER_PROFILE",
    }),
  },
  beforeMount() {
    let user_data = window.localStorage.getItem("user");
    let json_user = JSON.parse(user_data);
    this.SET_USER_PROFILE(json_user);
    //this.SET_USER_PROFILE(this.$cookies.get('user'))
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
body {
  background-color: #011447 !important;
}

#app {
  min-width: 320px;
  max-width: 1400px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--dark-blue-color);
}

/*
  Fonts
*/

@font-face {
  font-family: "Geometria-light";
  font-style: normal;
  font-weight: 300;
  src: local("Geometria-light"),
    url("./assets/fonts/Geometria-light.woff2") format("woff2"),
    url("./assets/fonts/Geometria-light.woff") format("woff");
}

@font-face {
  font-family: "Geometria-normal";
  font-style: normal;
  font-weight: 400;
  src: local("Geometria-normal"),
    url("./assets/fonts/Geometria-normal.woff2") format("woff2"),
    url("./assets/fonts/Geometria-normal.woff") format("woff");
}

@font-face {
  font-family: "Geometria-medium";
  font-style: normal;
  font-weight: 500;
  src: local("Geometria-medium"),
    url("./assets/fonts/Geometria-medium.woff2") format("woff2"),
    url("./assets/fonts/Geometria-medium.woff") format("woff");
}

@font-face {
  font-family: "Geometria-bold";
  font-style: normal;
  font-weight: 700;
  src: local("Geometria-bold"),
    url("./assets/fonts/Geometria-bold.woff2") format("woff2"),
    url("./assets/fonts/Geometria-bold.woff") format("woff");
}

@font-face {
  font-family: "Geometria-lf";
  font-style: normal;
  font-weight: 900;
  src: local("Geometria-lf"),
    url("./assets/fonts/Geometria-lf.woff2") format("woff2"),
    url("./assets/fonts/Geometria-lf.woff") format("woff");
}

/*
  Global styles
*/

body {
  font-family: "Geometria-normal", "Arial", sans-serif;
}

.button {
  background-color: #99cf00;
  color: #000000;
}

.SelectDropdown {
  .theme--light {
    &.v-list {
      background: #011c57 !important;
    }
    &.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: #fff !important;
    }
  }
}

.custom-success {
  --coordinates: 30px;

  position: fixed;
  right: var(--coordinates);
  bottom: var(--coordinates);
  z-index: -1;
  opacity: 0;

  padding: 1em 2em;

  text-transform: uppercase;
  color: #000000;
  font-weight: 700;

  background-color: rgb(153, 207, 0);
}

.custom-success_state_active {
  z-index: 1;

  animation-name: fadeInDown;
  animation-duration: 2s;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
