"use strict";

import Vue from 'vue';
import axios from "axios";
import errorHandler from './axios_error_handler'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const addSlash = (url) => {
  if (!RegExp('\\?|/$').test(url)) {
    let oldUrl = url
    console.log(`replace ${oldUrl} to ${url}`)
    return url + '/'
  }
  let index = url.indexOf('?')
  if (index > 0 && url[index - 1] !== '/') {
    return url.slice(0, index) + '/' + url.slice(index, url.length)
  }
  return url
}

let config = {
  // baseURL: process.env.VUE_APP_DEV_SERVER
  //   ? '/api-proxy/api/v1'
  //   : '/api/v1'
  baseURL: '/api/v1'
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      config.headers['Authorization'] = window.auth.token ? `token ${window.auth.token}` : ''

      // Добавляем слэш, если его нет, чтоб избежать редиректа джанги в (браузере safari не сохраняется Authorization)
      config.url = addSlash(config.url)

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      errorHandler(error)

      return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;

  _axios.interceptors.request.use((config) => {
    Vue.prototype.$loader?.start()
    return config
  }, (error) => {
    Vue.prototype.$loader?.end()
    return Promise.reject(error)
  })

  window.axios.interceptors.response.use((response) => {
    Vue.prototype.$loader?.end()
    return response
  }, function(error) {
    Vue.prototype.$loader?.end()
    return Promise.reject(error)
  })

  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios;
      }
    },
    $axios: {
      get () {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin)

export default Plugin;