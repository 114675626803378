import Vue from 'vue'
import Vuex from 'vuex'
import tour from './tour'
import request from './request'

Vue.use(Vuex)
export default new Vuex.Store({
  strict: false,
  state: {
    user: null,
  },
  actions: {
    async GET_ENTERING_RESULTS_FROM_API({commit}, { id }) {
      const {data} = await Vue.axios.get(`/tournament_table/group_info/${id}/`)
      commit('SET_ENTERING_RESULTS_TO_VUEX', data)
    },
    async GET_USER({commit}) {
      const {data} = await Vue.axios.get('/user_profile/');
      commit('SET_USER_PROFILE', data)
      return data
    }
  },
  mutations: {
    SET_ENTERING_RESULTS_TO_VUEX: (state, tour) => {
      state.tour = tour
    },
    SET_USER_PROFILE(state, user){
      state.user = user
      console.log(state);
    }
  },
  getters: {
    TOUR(state) {
      return state.tour;
    },
    user: state => state.user
  },
  modules:{
    tour,
    request
  }
})
