// Плагин реализует логику авторизации на сайте с помощью токена, а так же его хранения в хранилище браузера.
// Позволяет добавить автомачиский проверку авторизации у неавторизованного пользователя
// перед посещением всех страниц и редиректнуть его на страницу авторизации
// (кроме тех, у которых в роутинге: meta: { noAuth: true} )
//
// Настройка:
//
// Vue.use(auth, {
//     tokenGetter: async ( username, password ) => {
//         let { data } = await window.axios.post( 'get-token/', { username, password })
//         return data?.token ? data.token : ''
//     },
//     onLogout: () => {
//         router.push({ name: "Login" })
//     }
// })
//
// Использование:
//
// доступ к auth можно получить через window.auth либо this.$auth внутри компонента
//
// auth.login(username, password) - получает токен и хранит его в auth.token
// auth.logout() - очистить токен
// auth.isAuth - флаг наличия токена
//
// для добавления логики проверки авторизации перед перходами:
//
// router.beforeEach( window.auth.createPageGuard( '/login' ) ) // в качестве параметра страница авторизации (по умолчанию '/login')

import Vue from 'vue'

const EXCLUDE_META_TAG = 'noAuth'
const NULL_FUNCTION = () => { }

// class Auth {
const plugin = new Vue({
    data: () => ({
        token: '',
    }),
    computed: {
        isAuth() {
            return Boolean(this.token)
        }
    },
    methods: {
        create({ tokenGetter, onLogout: onLogout = NULL_FUNCTION }) {
            this.tokenGetter = tokenGetter
            this.onLogout = onLogout
            // this._setToken(localStorage.getItem('token'))
            this._setToken(window.localStorage.getItem( 'token' ))
            return this
        },

        async login(username, password) {
            this._setToken(await this.tokenGetter(username, password))
            return new Promise((resolve, reject) => {
                this.isAuth ? resolve() : reject()
            })
        },

        logout() {
            window.localStorage.removeItem('token')
            this._setToken()
            this.onLogout()
        },

        createPageGuard(loginPage = '/login') {
            const canResolve = (to) => to.meta[EXCLUDE_META_TAG] || this.isAuth

            return (to, from, next) => {
                if (!canResolve(to) && (to.path !== loginPage)) {
                    next(loginPage)
                } else if (canResolve(to) && (to.path === loginPage)) {
                    next('/')
                } else {
                    next()
                }
            }
        },

        _setToken(token = '') {
            this.token = token
            window.localStorage.setItem('token', this.token)
            // localTest
            console.log('context token = ' + this.token);
            console.log('localstor token = ' + window.localStorage.getItem('token'))
        },
    }
})

export default {
    install(Vue, { tokenGetter, onLogout }) {
        window.auth = plugin.create({ tokenGetter, onLogout })

        Object.defineProperties(Vue.prototype, {
            $auth: {
                get() {
                    return window.auth
                }
            }
        })
    }
}
