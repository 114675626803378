import Vue from 'vue'
import router from '../router'
import AuthPlugin from './custom_plugins/auth'

Vue.use(AuthPlugin, {
    tokenGetter: async ( username, password ) => {
        let { data } = await window.axios.post('get-token/', { username, password })
        return data?.token ? data.token : ''
    },
    onLogout: () => {
        router.push({ name: "Home" })
    }
})
