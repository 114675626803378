export default {
  carouselKey: state => state.carouselKey,
  open_tournaments: state => state.open_tournaments,
  players: state => state.players ?? [],
  new_team: state => state.new_team,
  showRequestDataExistModal: state => state.showRequestDataExistModal,
  isRequestDataExist: state => state.isRequestDataExist,
  isSubmitted: state => state.isSubmitted,
  teams: state => state.teams,
  lineups: state => state.lineups,
  lineup_for_edit: state => state.lineup_for_edit,
  lineup_comments: state => state.lineup_comments,
  lineups_by_state: state => state.lineups_by_state
}
