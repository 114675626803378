import flow from "lodash/fp/flow"
import groupBy from "lodash/fp/groupBy"
import Vue from 'vue'
import tourNames from "../../constants/tourNames"

export default {
  async GET_TOURNAMENT_TABLE_NAVIGATION({commit},options){
    const {data = []} = await Vue.axios.get('/tournament_table_navigation/');
    commit('SET_TOURNAMENT_TABLE_NAVIGATION', data)

    let current_year = options?.tour_year || data?.reverse()[0]?.year

    commit('SET_CURRENT_TOURNAMENT_YEAR', current_year)
    let names = []
    let numbers = []
    let current_number = ''
    data.map(item => {
      if(item.year === current_year) {
        const dataset = JSON.parse(JSON.stringify(item.dataset))
        dataset.sort((a,b) => (a.id - b.id)).map(itm => {
          names.push(itm.tournament_type)
          let current_name = options?.tour_name || tourNames?.[names[0]]
          if(tourNames?.[itm.tournament_type] === current_name){
            let tours = itm.tours || []
            let currentTour = tours.find(tour => tour.is_current)
            numbers = tours.map(tour => tour?.tour_number)
            current_number = currentTour?.tour_number || numbers[0]
          }
        })
      }
    })
    let current_name = options?.tour_name ||  tourNames?.[names[0]]
    commit('SET_TOURNAMENT_NAMES', names)
    commit('SET_TOURNAMENT_NUMBERS', numbers.sort((a,b) => (a - b)));
    commit('SET_CURRENT_TOURNAMENT_NAME', current_name)
    commit('SET_CURRENT_TOURNAMENT_NUMBER', +options?.tour_number ? numbers.includes(+options?.tour_number) ? +options?.tour_number : current_number : current_number)
  },
  async GET_TOURNAMENT_DIVISIONS({commit}, {id}) {
    const {data:{groups}} = await Vue.axios.get(`/tournament_table/${id}/`);
    const map = require('lodash/fp/map').convert({ 'cap': false });

    const filteredGroups = flow(
      groupBy('division'),
      map((divisions, division) => ({division, divisions}))
    )(groups)

    commit('SET_TOURNAMENT_DIVISIONS', filteredGroups)

  },
  async GET_GROUP_INFO({commit}, {id}){
    const {data} = await Vue.axios.get(`/tournament_table/group_info/${id}/`);
    commit('SET_GROUP_INFO', data || null)
  },
  async GET_TOUR_FOR_EDIT({commit}, {id}){
    const {data} = await Vue.axios.get(`/tour_lineups/${id}/`)
    commit('SET_ORIGINAL_TOUR_FOR_EDIT',data.lineups)
    let divisionNames = new Set()
    data.lineups.map(lineup => {
      if(lineup?.group?.division){
        divisionNames.add(lineup?.group?.division)
      }
    })
    let byDivision = flow(groupBy('group.division'))(data.lineups)
    let byNameDivision = {}
    Object.keys(byDivision).map(key => {
      byNameDivision[key] = flow(groupBy('group.name'))(byDivision[key])
    })
    let toursForEdit = []
    let _divisionNames = [...divisionNames]
    _divisionNames.map(name => {
      toursForEdit.push({
        name,
        groups: byNameDivision[name]
      })
    })
    if(byNameDivision?.['undefined']){
      commit('SET_TOUR_UNDEFINED_GROUPS', byNameDivision?.['undefined']?.undefined)
    }else{
      commit('SET_TOUR_UNDEFINED_GROUPS',[])
    }
    commit('SET_TOUR_FOR_EDIT', toursForEdit)
  },
  async SAVE_TOUR(state, {id, lineups}){
    const {data} = await Vue.axios.put(`/tour_lineups/${id}/`,{
      lineups
    });
    return data
  },
  async UPLOAD_PROTOCOL(state, {id, file}){
    let formData = new FormData()
    formData.set('protocol', file, file.name);
    const {data} = await Vue.axios.put(`/group_results/${id}/`,formData);
    return data
  },
  async UPDATE_GROUP_RESULTS(state, {id, data}){
    return await Vue.axios.put(`/group_results/${id}/`, data)
  },
  async CONFIRM_GROUP_RESULTS(state, {id}) {
    return await Vue.axios.put(`/group_results/${id}/confirm_results/`)
  }
}
